/* istanbul ignore file */

import React from 'react';
import { array, string, shape, object, bool, oneOf } from 'prop-types';
import { Dropdown, DropdownItem } from '@andes/dropdown';

import componentCtrService from '../../../../services/component-ctr';
import Action from '../../../commons/action';
import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';
import Collection from './collection';

class Collections extends React.Component {
  constructor(props) {
    super(props);
    this.updateCollection = this.updateCollection.bind(this);
    this.state = this.initNewCollection(props.default, true);
  }

  initNewCollection(collection, lazyLoad) {
    return {
      collection: this.formatItems(collection, lazyLoad),
    };
  }

  formatItems(data, lazyLoad) {
    const { metadata, restClient } = this.props;

    data.items = data.items.map((item) => ({
      permalink: item.target_url,
      price: {},
      shipping: { free_shipping: false, label: '' },
      ...item,
      metadata,
      restClient,
    }));
    if (data.picture) {
      data.picture.lazyLoad = lazyLoad;
    }

    return data;
  }

  /* istanbul ignore next */
  updateCollection(event) {
    const collectionId = event?.target?.dataset?.key;
    const {
      collection: { id },
    } = this.state;

    const { restClient } = this.props;

    if (collectionId === id) {
      return;
    }

    restClient
      .get(`/collections/${collectionId}`)
      .then((response) => {
        this.setState(this.initNewCollection(response.data, false));
        componentCtrService.notifyPrint(response.data.items[0].track);
        componentCtrService.notifyPrint(response.data.track);
      })
      .catch(() => {});
  }

  render() {
    const { collection } = this.state;
    const {
      collections,
      title = null,
      deviceType,
      discover_label = null,
      lazyLoad,
      restClient,
    } = this.props;

    return (
      <Section type="collections">
        <div className="row container">
          <div className="section-header">
            <h2 className="collections__header">
              <span className={deviceType === 'desktop' ? 'cb-title' : ''}>
                {`${title}`}
              </span>
              <Dropdown
                value={collection.id}
                className="collections__header__dropdown"
                type="form"
                srLabel={collection.title}
                onChange={this.updateCollection}
              >
                {collections.map((collectionI) => (
                  <DropdownItem
                    key={collectionI.id}
                    value={collectionI.id}
                    title={collectionI.title}
                  />
                ))}
              </Dropdown>
            </h2>
          </div>
          <Collection
            {...collection}
            discover_label={discover_label}
            lazyLoad={lazyLoad}
            restClient={restClient}
            deviceType={deviceType}
            imgSize={deviceType === 'mobile' ? '160' : '184'}
          />
          {deviceType === 'mobile' && (
            <Action
              {...collection.view_more_action}
              className="section-button"
            />
          )}
        </div>
      </Section>
    );
  }
}

Collections.propTypes = {
  collections: array.isRequired,
  discover_label: string,
  default: shape({
    view_more_action: object.isRequired,
  }).isRequired,
  lazyLoad: bool.isRequired,
  metadata: shape({}).isRequired,
  restClient: object.isRequired,
  title: string,
  type: string.isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

export default withTracker(Collections);
