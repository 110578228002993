import React from 'react';
import { string, bool, shape } from 'prop-types';
import classNames from 'classnames';
import Image from '../image';

function SquaredItem({ permalink, className = null, imgSize = null, lazyLoad, title, picture }) {
  return (
    <div className="squared-item__wrapper">
      <a
        href={permalink}
        className={classNames('squared-item', className)}
      >
        <Image size={imgSize} lazyLoad={lazyLoad} alt={title} {...picture} />
        <div className="squared-item__content">
          {title
            && (
            <p className="squared-item__title" aria-hidden="true">
              {title}
            </p>
            )}
        </div>
      </a>
    </div>
  );
}

SquaredItem.propTypes = {
  id: string.isRequired,
  lazyLoad: bool.isRequired,
  title: string.isRequired,
  className: string,
  permalink: string.isRequired,
  picture: shape(Image.propTypes).isRequired,
  imgSize: string,
};

export default SquaredItem;
