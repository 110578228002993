import React from 'react';
import { string, bool, shape } from 'prop-types';
import Image from '../../../commons/image';

function CollectionItem({
  title = null,
  picture,
  discover_label = null,
  view_more_action: { target },
}) {
  return (
    <div>
      <a href={target} className="ui-card large">
        <Image className="lazyload" {...picture} alt={title} />
        <div className="ui-card foot-main">
          <span className="main-item-label">{discover_label}</span>
          <span className="main-item-title">{title}</span>
        </div>
      </a>
    </div>
  );
}

CollectionItem.propTypes = {
  discover_label: string,
  lazyLoad: bool.isRequired,
  picture: shape(Image.propTypes).isRequired,
  view_more_action: shape({
    target: string.isRequired,
  }).isRequired,
  title: string.isRequired,
};

export default CollectionItem;
