import React from 'react';
import ReactDOM from 'react-dom';
import { string, arrayOf, bool, object, shape } from 'prop-types';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import componentCtrService from '../../../../services/component-ctr';
import SquaredItem from '../../../commons/squared-item';
import divideByGroupsOf from '../divide-groups';
import CollectionItem from './collection-item';

const whenIdle = globalThis.requestIdleCallback || setTimeout;

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.track = this.track.bind(this);
  }

  componentDidMount() {
    // I do need to get the dom node here, so disable lint.
    if ('IntersectionObserver' in globalThis) {
      // eslint-disable-next-line
      this.element = ReactDOM.findDOMNode(this);
      if (this.element) {
        // element is null when WrappedComponent returns null
        this.observer = new globalThis.IntersectionObserver(this.track, {
          threshold: 0.5,
        });
        this.observer.observe(this.element);
      }
    }
  }

  // Tracks the wrapped component.
  track(entries) {
    const { items, track } = this.props;

    if (!entries[0].isIntersecting) return;
    this.observer.unobserve(this.element);
    this.observer.disconnect();
    whenIdle(() => {
      componentCtrService.notifyPrint(items[0].track);
      componentCtrService.notifyPrint(track);
    });
  }

  render() {
    const { lazyLoad, imgSize = null, items, deviceType, ...rest } = this.props;
    const groupedItems = divideByGroupsOf(items, 2);

    if (deviceType === 'tablet') {
      return (
        <div className="collection">
          <CarouselFree>
            <CarouselFreeSlide>
              <div className="main-item">
                <CollectionItem
                  {...{ lazyLoad, imgSize, items, deviceType, ...rest }}
                />
              </div>
            </CarouselFreeSlide>
            {groupedItems.map((group) => (
              <CarouselFreeSlide key={group.id}>
                <div className="other-items-group">
                  {group.elements.map((element) => (
                    <SquaredItem
                      key={element.id}
                      imgSize={imgSize}
                      permalink={element.target_url}
                      lazyLoad={lazyLoad}
                      {...element}
                    />
                  ))}
                </div>
              </CarouselFreeSlide>
            ))}
          </CarouselFree>
        </div>
      );
    }

    return (
      <div className="collection">
        <div className="main-item">
          <CollectionItem
            {...{ lazyLoad, imgSize, items, deviceType, ...rest }}
          />
        </div>
        <div className="other-items">
          {items.map((item) => (
            <SquaredItem
              key={item.id}
              imgSize={imgSize}
              permalink={item.target_url}
              lazyLoad={lazyLoad}
              {...item}
            />
          ))}
        </div>
      </div>
    );
  }
}

Collection.propTypes = {
  discover_label: string,
  items: arrayOf(shape({})).isRequired,
  lazyLoad: bool.isRequired,
  restClient: object.isRequired,
  view_more_action: shape({
    target: string.isRequired,
  }).isRequired,
  title: string.isRequired,
  imgSize: string,
  deviceType: string,
  track: shape({}),
};

export default Collection;

